<template>
  <VFileList
    :files="getFiles"
    is-download
    grid
  />
</template>

<script>
import VFileList from '@/components/list/FileList/VFileList'
import { useStore } from 'vuex'
import { computed, onBeforeMount } from 'vue'
import { getUser } from '@/http/user/userApi'
import { useRouter } from 'vue-router'
import { RenderApiError } from '@/extension/Error/RenderApiError'

export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const handlerErrors = new RenderApiError()

    onBeforeMount(async () => {
      try {
        store.commit('files/setFiles', [])
        const id = router.currentRoute.value.params?.id ? router.currentRoute.value.params.id : store.getters['auth/getUser'].id
        const { data } = await getUser(id)
        const files = []
        Object.keys(data).forEach(key => {
          const values = data[key.includes('file') ? key : null]
          if (values) return files.push(...values)
        })
        store.commit('files/setFiles', files)
      } catch (e) {
        await handlerErrors.showAlert(e)
      }
    })

    const getFiles = computed(() => {
      return store.getters['files/getFiles'] || []
    })
    return {
      getFiles
    }
  },
  components: {
    VFileList
  }
}
</script>
